<template>
  <PageCard pageName="All Groups" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <v-wait for="fetchingGroups">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row">
          <div class="col-sm-3 text-left mb-3">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filterText"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-sm-7 text-right">
            <router-link
              tag="button"
              :to="{ path: '/super_admin/groups/new' }"
              class="btn btn-primary fw-btn"
            >
              New Group
            </router-link>
          </div>
        </div>
        <div class="col-sm-10">
          <b-table
            :items="listingGroups"
            :fields="tableFields"
            :show-empty="true"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filterText"
            :filter-included-fields="filterOn"
            @filtered="afterFilter"
            empty-text="No records found."
            responsive="md"
            striped
            hover
            small
          >
            <template v-slot:cell(editGroup)="data">
              <router-link
                :to="{
                  name: 'SuperAdminEditGroup',
                  params: { id: data.item.id }
                }"
              >
                Edit
              </router-link>
            </template>
            <template v-slot:cell(deleteGroup)="data">
              <a
                href="#"
                @click="setSelectedGroup(data.item)"
                v-b-modal.deleteConfirmation
                >Delete</a
              >
            </template>
          </b-table>
          <b-row>
            <b-col md="6">
              <b-pagination
                :total-rows="filteredGroups ? filteredGroups.length : totalRows"
                :per-page="perPage"
                v-model="currentPage"
              >
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <b-modal
          id="deleteConfirmation"
          ref="deleteConfirmation"
          title="Delete Group"
          @ok="deleteGroupData"
          @cancel="resetSelectedGroup"
        >
          <p>Are you sure, want to delete the group?</p>
        </b-modal>
      </v-wait>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AllGroups',
  components: {
    PageCard
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchAllGroups()
  },
  data() {
    return {
      groups: [],
      currentPage: 1,
      perPage: 20,
      selectedGroup: null,
      filterText: null,
      filteredGroups: null,
      filterOn: ['name', 'group_type']
    }
  },
  computed: {
    listingGroups() {
      return this.groups.filter(group => !group.isDeleted)
    },
    totalRows() {
      return this.listingGroups.length
    },
    tableFields() {
      return [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'group_type', label: 'Group type', sortable: true },
        { key: 'editGroup', label: '', sortable: false },
        { key: 'deleteGroup', label: '', sortable: false }
      ]
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.filteredGroups = filteredItems
      this.currentPage = 1
    },
    setSelectedGroup(group) {
      this.selectedGroup = group
    },
    resetSelectedGroup() {
      this.selectedGroup = null
    },
    fetchAllGroups() {
      this.$wait.start('fetchingGroups')
      this.$http
        .get('/api/groups', {
          handleErrors: true,
          params: {
            super_admin: true
          }
        })
        .then(res => {
          res.data.groups.forEach(group => (group.isDeleted = false))
          this.groups = res.data.groups
        })
        .then(() => {
          this.$wait.end('fetchingGroups')
        })
    },
    deleteGroupData(evt) {
      evt.preventDefault()

      this.$http
        .delete('/api/groups/' + this.selectedGroup.id, {
          handleErrors: true
        })
        .then(res => {
          this.setSelectedGroup({})
          this.maskGroupAsDeleted(res.data.group)
          this.$refs.deleteConfirmation.hide()
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    maskGroupAsDeleted(groupId) {
      let group = this.groups.find(group => group.id === groupId)
      group.isDeleted = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
